$(document).ready(function () {

	'use strict';

	App.MainMenu = function (element, target) {

		const $element = $(element);
		const $target = $(target);
		$target.addClass('hidden');

		let close = function (e) {
			e.preventDefault();
			$target
				.removeClass('open')
				.addClass('close')
				.attr('aria-hidden', 'true');
			setTimeout(() => {
				$target.addClass('hidden');
			}, 300);
		}

		// when the menu is bigger than the windowheight add a scrollbar on y-axis
		let menuScrollY = function () {

			let menu = $target;
			let windowheight = $(window).height();
			let menuheight = menu.height();

			if (menuheight > windowheight) {

				menu.css({
					'overflow-y': 'scroll',
					'bottom': '0'
				});
			} else {
				menu.css({
					'overflow-y': 'hidden',
					'bottom': 'unset'
				});
			}
		}

		let submenu = function () {

			let isvisible = $('.sublevel.visible').length > 0;
			if (!isvisible) {

				let $sublevel = $('.sublevel');
				let $firstLevel = $('.first-level');
				let $activeAnchor = $('.first-level a.active');

				if ($activeAnchor.length > 0) {
					secondlevel($activeAnchor[0]);
				} else {
					$('.active', $sublevel);
					$sublevel.eq(0).addClass('visible');
					$sublevel.eq(0).siblings().addClass('hidden');
					$('li:first-child a', $firstLevel).addClass('active');
				}
			}
		}

		// parameter id = open this id in first-level

		let menuTrigger = function (e, id = undefined) {

			console.log('menuTrigger');

			var screenWidth = window.innerWidth;

			if (screenWidth > 1023) {

				$('.menu-button-megamenu').attr('aria-hidden', 'false');

				let isopen = $target.hasClass('open');
				if (isopen) {
					close(e);
				} else {
					$target.removeClass('hidden')
					setTimeout(() => {
						$target
							.addClass('open')
							.removeClass('close')
							.attr('aria-hidden', 'false');
					}, 5);
				}

				submenu();

				if ($target.hasClass('open')) {
					let $window = $(window);
					$window.resize(function () {
						menuScrollY();
					});
					$window.trigger('resize');
				}

				if (id != null) {
					$('.menu .first-level li').each(function () {
						var currentItemId = $(this).find('a').data('id');
						if (currentItemId === id) {
							$(this).find('a').click();
							return false;
						}
					});
				}
			}

			//if (id && screenWidth < 1024) {
			if (screenWidth < 1024) {
				console.log('.menu-button a click()');
				$('.menu-button a').click();
				$('.menu-button-megamenu').attr('aria-hidden', 'true');
			}
		}

		$element.on('touchstart click', function (e) {
			menuTrigger(e);
			e.preventDefault();
		});

		// menu handlers for keyboard
		$element.on('focus', function (e) {

			$(this).keyup(function (event) {

				if (event.keyCode === 13) {
					menuTrigger(e);
				}
			});
		});

		$target.after().on('touchstart click', function (e) {

			let inside = $(e.target).closest('.inside').length;
			let menutrigger = $(e.target).closest('.menu-button-megamenu, .ommelander-ziekenhuis-logo').length;

			if (!inside && !menutrigger) {
				close(e);
			}

		});

		let secondlevel = function (el, e) {

			if (arguments[1] !== undefined && !el.attr('href').length) {
				console.log('e.preventDefault()');
				e.preventDefault();
			}

			let id = $(el).attr('data-id');

			$('.sublevel').each(function (index, element) {

				let current = $(element).attr('data-id');
				let $element = $(element);

				if (id == current) {

					$element.addClass('pre');
					$element.siblings()
						.removeClass('visible')
						.addClass('hidden');

					setTimeout(function () {

						$element.removeClass('pre');
						$element.addClass('visible');

					}, 200);

				}

			});
		}

		// first level navigation
		$('.first-level li a').on('click', function (e) {

			console.log('first-level on click');
			console.log('first-level on click e', e);

			$(this)
				.addClass('active')
				.parent('li')
				.siblings()
				.find('a')
				.removeClass('active');

			secondlevel($(this), e);

		});

		$('.mmenu-open').on('click', function (e) {
			console.log("mmenu-open");

			e.preventDefault();

			var context = $(this);
			var dataId = context.data('id');

			menuTrigger(e, dataId);

		});
	};

	App.MainMenu('.menu-button-megamenu, .close-button, .breadcrumbs .label', '.menu');

});